<template>
  <div class="home" :class="isEn?'home-en':''">
    <!-- 内容1 -->
    <div class="home-1 relative">
      <!-- 暂时不要视频只要图 -->
      <!-- <video 
      id="myVideo" 
      class="video-js vjs-default-skin vjs-big-play-centered"  
      muted 
      autoplay 
      preload 
      loop>
      <source 
      src="https://immotor-china.oss-cn-shenzhen.aliyuncs.com/scooterlife/OneSwap-official-website/banner.mp4" 
      type="video/mp4">
      <track kind="captions">
      </video>
      <transition name="fade">
        <div class="vedio-text" v-if="showText">
          <h2 class="h-1 fz56 gapB24 left">{{$t('vedio_h1')}}</h2>
          <p class="h-3 fz22 fw500 left">{{$t('vedio_p1')}}</p> 
        </div>
      </transition> -->
      <el-image  lazy :src="require('../assets/home/home_bg.jpg')" class='banner-1'/>
      <div class="vedio-text" v-if="showText">
        <h2 class="h-1 fz56 gapB24 left">{{$t('vedio_h1')}}</h2>
        <p class="h-3 fz22 fw500 left">{{$t('vedio_p1')}}</p> 
      </div>
      
      <div class="download">
        <img :src="require('../assets/videos/download_code.png')"  />
        <p class="fz14 fw400">{{$t('download')}}</p> 
      </div>
    </div>
    <!-- 内容2 -->
    <div class="home-2 paddT100">
        <h2 class="h-1">{{$t('hh12_1')}}</h2>
        <div class="home-con">
          <ul class="ul-content left">
            <li>
              <h2 class="h2-7 gapT10"><span class="span1 fw700 fz48">{{$t('hh2_1_1')}}</span>&nbsp;<span class="p3">{{$t('hh2_2_1')}}</span></h2>
              <p class="p2 gapT10">{{$t('hh2_1')}}</p>
            </li>
            <li>
              <h2 class="h2-7 gapT10"><span class="span1 fw700 fz48">{{$t('hh2_1_2')}}</span>&nbsp;<span class="p3">{{$t('hh2_2_2')}}</span></h2>
              <p class="p2 gapT10">{{$t('hh2_2')}}</p>
            </li>
            <li>
              <h2 class="h2-7 gapT10"><span class="span1 fw700 fz48">{{$t('hh2_1_3')}}</span>&nbsp;<span class="p3">{{$t('hh2_2_3')}}</span></h2>
              <p class="p2 gapT10">{{$t('hh2_3')}}</p>
            </li>
            <li>
              <h2 class="h2-7 gapT10"><span class="span1 fw700 fz48">{{$t('hh2_1_4')}}</span>&nbsp;<span class="p3">{{$t('hh2_2_4')}}</span></h2>
              <p class="p2 gapT10">{{$t('hh2_4')}}</p>
            </li>
          </ul> 
        </div>
    </div>

    <!-- 内容3 -->
    <div class="home-3 padd100-100">
        <h2 class="h-1 gapB64 fw700">{{$t('hh13_1')}}</h2>
        <div class="home-3-con">
          <ul class="nav gapB64">
            <li 
            v-for="(item ,index) in tabs"
            :class="{ left:index===1,right:index===0 }"
            :key="index">
              <span @click.stop="curTab = index" class="outLi" :class="{ active: index===curTab,fz24:true,fw700:index===curTab}">{{$t(item.name)}}</span>
            </li>
          </ul>
          <div class="con-box">
            <div class="con-text left">
              <h3 class="h-2 gapB80 fz36 fw500">{{$t(product.title)}}</h3>
              <ul class="detail">
                <li 
                class="fz14 fw400 gapB24"
                v-for="(item ,index) in product.details"
                :key="index">
                  <img class="home-3-icon" :src="item.icon" alt="">
                  {{$t(item.name)}}
                </li>
              </ul>
            </div>
            <div class="con-img">
              <img :src="product.img" alt="">
            </div>
          </div>
        </div>
        
        <div class="home-3-btn">
          <div class="home-btn" @click="handleProduct">
            <span class="fz14 fw400">{{$t("hh3_btn")}}</span>
            <img :src="require('../assets/home/icon_right.png')"  />
          </div>
        </div>
    </div>

    <!-- 内容4 -->
    <div class="home-4 padd100-100">
      <h2 class="h-1 gapB20 fw700">{{$t("hh14_1")}}</h2>
      <p class="gapB64 fz14 fw400">{{$t("hh14_2")}}</p>
      <div class="home-con">
        <img :src="require('../assets/home/home_3_img.png')"  />
      </div>
    </div>

    <!-- 内容5 -->
    <div class="home-5 padd100-100">
      <h2 class="h-1 gapB20 fw700">{{$t("hh15_1")}}</h2>
      <p class="gapB64 fz14 fw400">{{$t("hh15_2")}}</p>
      <div class="home-con">
        <ul class="ul-content">
          <li v-for="(item,index) in photoData" class="relative" :key="index">
            <img :src="item.imgUrl" alt=""/>
            <h2 class="p1 fz24 fw700">{{$t(item.name)}}</h2>        
          </li>
        </ul> 
      </div> 
    </div>

    <!-- 内容6 -->
    <div class="home-6 padd100-100">
        <h2 class="h-1 gapB80">{{$t("hh16")}}</h2>
        <div class="home-con">
          <ul class="ul-content left">
            <li>
              <h3 class="fz24 fw700 gapB48">{{$t('hh16_1')}}</h3>
              <p class="p2">{{$t('hh16_p_1')}}</p>
            </li>
            <li>
              <h3 class="fz24 fw700 gapB48">{{$t('hh16_2')}}</h3>
              <p class="p2">{{$t('hh16_p_2')}}</p>
            </li>
            <li>
              <h3 class="fz24 fw700 gapB48">{{$t('hh16_3')}}</h3>
              <p class="p2">{{$t('hh16_p_3')}}</p>
            </li>
            <li>
              <h3 class="fz24 fw700 gapB48">{{$t('hh16_4')}}</h3>
              <p class="p2">{{$t('hh16_p_4')}}</p>
            </li>
          </ul>
        </div>
    </div>

    <!-- 内容7 -->
    <div class="relative home-7">
      <img class="home-7-img" :src="require('../assets/home/home_7_img.png')"  />
      <div class="text-box">
        <h4 class="h-4 gapB12">{{ $t("hh17_1") }}</h4> 
        <h2 class="h-b gapB40">{{ $t("hh17_2") }}</h2>
        <!-- <a target="_blank" class="btn" href=" mailto:global.business@immotor.com" v-if="isEn">{{$t("btn_2")}}</a>   -->
        <div class="home-btn" @click="handleConnect">
          <span class="fz14 fw400">{{$t("btn_2")}}</span>
          <img :src="require('../assets/home/icon_right.png')"  />
        </div>      
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed:{
    isEn:function(){
      return this.$store.state.isEn
    },
    product(){
      return this.tabs[this.curTab].product;
    }
  },
  data() {
    return {
      subMenu: 1,
      showText:true,
      elevideo:null,
      timer:null,
      //业务模式数据
      photoData:[
        {
          imgUrl:require('../assets/home/home_5_img2.png'),
          name:'hh5_con_1'
        },
        {
          imgUrl:require('../assets/home/home_5_img3.png'),
          name:'hh5_con_2'
        },
        {
          imgUrl:require('../assets/home/home_5_img1.png'),
          name:'hh5_con_3'
        },
        {
          imgUrl:require('../assets/home/home_5_img4.png'),
          name:'hh5_con_4'
        },
        {
          imgUrl:require('../assets/home/home_5_img5.png'),
          name:'hh5_con_5'
        },
        {
          imgUrl:require('../assets/home/home_5_img6.png'),
          name:'hh5_con_6'
        },
      ],
      tabs:[
        {
          name:"hh3_tab_1",
          product:{
            title:"hh3_con_t1",
            details:[
              {name:"hh3_con_d1_1",icon:require('../assets/home/icon_1.png')},
              {name:"hh3_con_d1_2",icon:require('../assets/home/icon_8.png')},
              {name:"hh3_con_d1_3",icon:require('../assets/home/icon_3.png')},
              {name:"hh3_con_d1_4",icon:require('../assets/home/icon_10.png')},
              {name:"hh3_con_d1_5",icon:require('../assets/home/icon_4.png')},
              {name:"hh3_con_d1_6",icon:require('../assets/home/icon_5.png')},
            ],
            img:require('../assets/home/home_3_station.png'),
          }
        },
        {
          name:"hh3_tab_2",
          product:{
            title:"hh3_con_t2",
            details:[
              {name:"hh3_con_d2_1",icon:require('../assets/home/icon_6.png')},
              {name:"hh3_con_d2_2",icon:require('../assets/home/icon_2.png')},
              {name:"hh3_con_d2_3",icon:require('../assets/home/icon_11.png')},
              {name:"hh3_con_d2_4",icon:require('../assets/home/icon_4.png')},
              {name:"hh3_con_d2_5",icon:require('../assets/home/icon_9.png')},
              {name:"hh3_con_d2_6",icon:require('../assets/home/icon_7.png')},
            ],
            img:require('../assets/home/home_3_battery.png'),
          }
        },
      ],
      curTab:0
    };
  },
  created(){},
  mounted(){
    // this.elevideo = document.getElementById('myVideo');
    // this.elevideo.addEventListener('playing', () => {
    //         //播放中
    //         this.timer = setInterval(() => {
    //           if(parseFloat(this.elevideo.currentTime)>=1.5){
    //             this.showText = false;
    //           }else{
    //             this.showText = true;
    //           }
    //         }, 500);
    //     });
    // this.elevideo.addEventListener(
    //     'ended',
    //     () => {
    //         //结束
    //         clearInterval(this.timer);
    //     },
    //     false
    // );
  },
  beforeDestory() {
    // this.elevideo.removeEventListener('playing', () => {});
    // this.elevideo.removeEventListener(
    //     'ended',
    //     () => {},
    //     false
    // );
    // clearInterval(this.timer);
    // this.elevideo = null;
  },
  methods:{
    handleProduct() {
      this.$router.push({
        path: "/product"
      });
      window.scrollTo(0,1590);  
    },
    handleConnect() {
      this.$router.push({
        path: "/join"
      });
      window.scrollTo(0,500);  
    },
     // 移入
    mouseOver2(subMenu) {
      this.subMenu = subMenu; 
    },
    // 回到顶部
    toTop() {
      window.scrollTo(0,0);
    }    
  },

};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
//英文样式
.home-en .home-6 {
  display: none;
}
.header-md.submenu_hide a{
  color: #fff!important; 
}
.header-md.submenu_hide ul li{
  color: #fff; 
}

@-webkit-keyframes warn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  5% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: scale(4);
    transform: scale(4);
  }
}
@keyframes warn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  5% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: scale(4);
    transform: scale(4);
  }
}
@-webkit-keyframes warn-2 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  5% {
    opacity: 8;
  }
  to {
    opacity: 0;
    -webkit-transform: scale(10);
    transform: scale(10);
  }
}
@keyframes warn-2 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  5% {
    opacity: 8;
  }
  to {
    opacity: 0;
    -webkit-transform: scale(10);
    transform: scale(10);
  }
}
// 公共
.block {
  display: block !important;
}
.home-con{
  display: flex;
  justify-content: center;
  width: 100%;
}
.ul-content{
  width:1200px;
  display: flex;
}
.home-btn{
  width: 180px;
  height: 40px;
  background: #165dff;
  cursor: pointer;
  color: #fff;
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  img{
    width: 24px;
    height: 24px;
  }
}

// 内容1
.home-1 .h-b{
  text-align: center;
}
.home-1{
  width: 100%;
  height: 100vh;
  position: relative;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  .banner-1{
    width: 100%;
    height: 100vh;
  }
}
.home-1 video {
    object-fit: fill;
    width: 100%;
    height: 100%;
    position: relative;
}
.vedio-text{
  left: 380px; 
  top: 312px;
  position: absolute;
  text-align: left;
}
.download {
  width:120px;
  height: 148px;
  left: 380px; 
  top: 512px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.10);
  position: absolute;
  text-align: center;
  letter-spacing: 0px;
  img{
    width: 112px;
    margin: 4px;
  }
}

// 内容3
.home-3{
  background: #f8f9fb;
  box-sizing: border-box;
  .home-3-con{
    background: #ffffff;
    margin: 0 auto 32px;
    width: 1440px;
    height: 640px;
    padding-top: 20px;
    .con-box{
      display: flex;
      .con-text{
        flex: 1;
        padding-left: 83px;
        padding-top: 36px;
        li{
          height: 22px;
          display: flex;
          align-items: center;
          line-height: 22px;
          color: #788496;
          letter-spacing: 1px;
        }
        .home-3-icon{
          width: 20px;
          height: 20px;
          display: inline-block;
          margin-right: 20px;
        }
      }
      .con-img{
        flex: 1;
        display: flex;
        justify-content: end;
        padding-right: 112px;
        img{
          width: 440px;
          height: 440px;
        }
      }
    }
  }
  .nav{
    display: flex;
    li{
      flex: 1;
      padding: 0 60px ;
    }
    .outLi {
      position: relative;
      padding: 16px 36px;
      color: #393f47;
      line-height: 32px;
      display:inline-block;
      &.active{
        color: #165DFF;
      }
      &.active::after {
        content: '';
        height: 4px;
        background: #165DFF;
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
      }
    }
  }
  .home-3-btn{
    display: flex;
    justify-content: center;
  }
}

// 内容2
.home-2{
  background-image: url(../assets/home/home_2_img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  height: 840px;
  box-sizing: border-box;
  h2{
    color: #fff;
    margin-bottom: 200px;
  }
  li{
    flex: 1;
    border-right: 1px solid #d9d9d9;
    padding-left: 96px;
    h2{
      margin-bottom: 0;
      color: #fff;
      display: flex;
      align-items: start;
    }
    p{
      color: #fff;
    }
    .span1{
      color: #fff;
      padding-right: 20px;
      display: inline-block;
      height: 56px;
      display: flex;
      align-items: start;
      line-height: 48px;
      position: relative;
      top: -4px;
      letter-spacing: 2px;
    }
  }
  li:nth-child(4){
    border-right: none;
  }
}

// 内容4
.home-4{
  background: #242933;
  box-sizing: border-box;
  img{
    width: 1360px;
    height: 942px;
  }
  h2{
    color: #fff;
  }
  p{
    color: rgba(255,255,255,0.80);
  }
}
.img-3{
  display: block;
  width: 62px;
  height: 62px; 
  margin: 0 auto;
}
.ul-4 {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.ul-4 li {
  flex: 1;
  overflow: hidden;
}
.span2{
  font-size: 30px;
  font-family: Bold;
  font-weight: 700;
  text-align: left;
  color: #30363e;
}
// 内容5
.home-5{
  background: #f8f9fb;
  p{
    color: #788496;
  }
  .ul-content{
    flex-wrap: wrap;
    justify-content: space-between;
    li{
      width: 387px;
      height: 216px;
      img{
        width: 387px;
        height: 216px;
      }
      h2{
        position: absolute;
        left: 32px;
        top: 24px;
        letter-spacing: 2px;
      }
    }
    li:nth-child(1),
    li:nth-child(2),
    li:nth-child(3){
      margin-bottom: 20px;
    }
  }
}
.home-6{
  background-image: url(../assets/home/home_6_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  height: 728px;
  box-sizing: border-box;
  .ul-content{
    justify-content: center;
    li{
      flex: 1;
      text-align: left;
      padding: 48px 32px 0 32px;
      height: 400px;
      box-sizing: border-box;
      letter-spacing: 1px;
    }
    li:hover{
      background-image: url(../assets/home/home_6_conbg.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      height: 400px;
      box-sizing: border-box;
      h3{
        color: #fff;
      }
      p{
        color: rgba(255,255,255,0.80);
      }
    }
  }
}
// 内容7
.home-7{
  height: 296px;
  background: #242933;
  display: flex;
  .home-7-img{
    width: 700px;
    height: 296px;
  }
  .text-box{
    padding: 60px 0 0 178px;
  }
}
.ul-9{
  width:1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.ul-9 li{
  width:180px;
}
.img-7{
  width: 68px;
  height: 68px;
}
@media (max-width:992px) {
  .rno-map-wrap {
      width: 100%;
  }
  .rno-2-section-inner{
    max-width:100%;
  }
  .home-5{
    height: auto;
  }
  .text-8{
    position: relative;
  }
  .rno-map-dot {
    width: 10px;
    height: 10px;
  }
  .rno-map-dot:after {
    width: 10px;
    height: 10px;
  }
  .rno-map-dot.style3 .rno-map-dot-layer {
    width: 4px;
    height: 4px;
    background-color: #ff7800;
    margin: -2px 0 0 -2px;
  }
  .rno-map-dot-text {
    font-size: 12px;
  }
  .ul-2 li {
      display: block;
  }
  .ul-2 li {
      width: 100%;
  }
  .two-box{
    width: 90%;
    position: relative;
    left: 0;
    top: 0;
    right:0;
    margin: 0 auto;
    padding-bottom: 80px;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .ul-2 .h-3{
  text-align: center;
  }
  .ul-1 {
    display: none;

  }
  .ul-9 li,
  .ul-9{
    width: 180px;
  }

  .ul-9 li{
      margin-bottom:40px;
  }
  .ul-9 li:nth-child(5){
      margin-bottom: 0px;
  }
}
/*Ipone 320*/
@media (max-width: 448px) {}
</style>